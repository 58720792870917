.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 320px;
    background-color: #fff;

    &__logo {
        display: grid;
        place-items: center;
        height: 120px;
        font-size: 1.5rem;
        font-weight: 700;
        font-family: 'Mochiy Pop P one', sans-serif;
    }

    &__menu {
        position: relative;

        &__item {
            display: flex;
            align-items: center;
            place-content: flex-start;
            padding: 1rem 3rem;
            font-size: 1.25rem;
            font-weight: 500;
            color: #555555;
            transition: color 0.3s ease-in-out;

            &.active {
                color: #fff;
            }

            &__icon {
                margin-right: 1rem;

                i {
                    font-size: 1.75rem;
                }
            }
        }

        &__indicator {
            position: absolute;
            top: 0;
            left: 50%;
            width: calc(100% - 40px);
            border-radius: 10px;
            background-color: #308efe;
            z-index: -1;
            transform: translateX(-50%);
            transition: 0.3s ease-in-out;
        }
    }
}