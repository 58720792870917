@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mochiy+Pop+P+One&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    background-color: #f0f0f0;
    color: #2b2b2b;
    font-family: 'Roboto', sans-serif;
}

a {
    text-decoration: none;
    color: unset;
}